<!--The content below is only a placeholder and can be replaced.-->
<header #header id="indato-header" class="navbar-fixed-top">
    <div class="container">
        <nav class="navbar navbar-default">
            <div class="navbar-header">
                <!-- Mobile Toggle Menu Button -->
                <a class="indato-nav-toggle" [ngClass]="{'active': !navCollapsed}" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar" (click)="$event.preventDefault(); navCollapsed=!navCollapsed"><i></i></a> 
                <a class="navbar-brand" href="index.html">
                    <picture>
                        <source srcset="assets/images/Bildmarke-rgb.png" media="(prefers-color-scheme:dark)">
                        <img src="assets/images/Bildmarke-rgb.png" title="{{ 'title' | translation }}">    
                    </picture>
                </a>
            </div>
            <div #navbar id="navbar" class="navbar-collapse collapse" aria-expanded="false" (click)="$event.preventDefault(); navBarClicked()">
                <ul class="nav navbar-nav navbar-right">
                    <li class="nav-item" pageScrollActive>
                        <a class="nav-link" href="#indato-home" pageScroll pageScrollAdjustHash="true" [pageScrollOffset]="-200" routerLink="/">
                            <span>{{'nav_home' | translation}}</span></a> 
                    </li>
                    <!-- 
                    <li class="nav-item" pageScrollActive>
                      <a class="nav-link" href="#indato-testimonials"  pageScroll pageScrollAdjustHash="true" routerLink="/">
                          <span>Angebot</span></a>
                    </li>
                    -->
                    <!-- 
                    <li class="nav-item" pageScrollActive>
                      <a class="nav-link" href="#indato-project"  pageScroll pageScrollAdjustHash="true" routerLink="/">
                          <span>Projekte</span></a>
                    </li>
                    -->
                    <li class="nav-item" pageScrollActive>
                        <a class="nav-link" href="#indato-company" pageScroll pageScrollAdjustHash="true" [pageScrollOffset]=setPageScrollOffset() routerLink="/">
                            <span>{{'nav_company' | translation}}</span></a>
                    </li>
                    <li class="nav-item" pageScrollActive>
                        <a class="nav-link" href="#indato-news" pageScroll pageScrollAdjustHash="true" [pageScrollOffset]=setPageScrollOffsetNews() routerLink="/">
                            <span>{{'nav_news' | translation}}</span></a>
                    </li>
                    <li class="nav-item" pageScrollActive>
                        <a class="nav-link" href="#indato-contact" pageScroll pageScrollAdjustHash="true" [pageScrollOffset]=setPageScrollOffset() routerLink="/">
                            <span>{{'nav_contact' | translation}}</span></a>
                    </li>
                </ul>
            </div>
        </nav>  
    </div>


</header>

<app-home></app-home>
<app-intro></app-intro> 
<!--app-testimonials></app-testimonials> -->
<!--app-project></app-project>  -->
<app-company></app-company>
<app-news></app-news>
<app-contact></app-contact>
