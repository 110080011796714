import { Words } from './words.model';

export let WORDS_DE: Words = {
    'title' : 'indato GmbH',
    'nav_home' : 'Home',
    'nav_company' : 'Unternehmen',
    'nav_news' : 'Aktuelles',
    'nav_contact' : 'Kontakt',
    'address' : 'Adresse',
    'approach' : 'Anfahrt',
    'text_company' : 'Die indato GmbH wurde im Jahr 2000 gegründet. Unsere Kunden sind Telekommunikationsanbieter, Versicherungen, IT-Dienstleister und die öffentliche Verwaltung.',
    'text_thomas_haenni' : 'Thomas Hänni, Jahrgang `63, ist Geschäftsführer und Mitbegründer der indato GmbH. Als Systemarchitekt ist er für die Konzeption und Umsetzung von Lösungen verantwortlich und arbeitet als Senior Developer selber bei der Umsetzung mit. Schwerpunkte seines Wirkens sind Java Enterprise, Open Source, Datenmodellierung und Informationssicherheit.',
    'text_thomas_oexl' : 'Thomas Oexl ist Geschäftsführer und Inhaber der indato GmbH. Als Projektleiter, Systemarchitekt und -integrator begleitet er Kundenprojekte von der Planung bis zur Inbetriebnahme.',
    'development' : 'Software Entwicklung',
    'maintenance' : 'Wartung und Support',
    'consultation' : 'Beratung und Mitarbeit',
    'text_development' : 'Wir entwickeln mit grosser Leidenschaft und viel Know-how wartbare und zweckmässige Applikationen, basierend auf Java und Open Source Software. Bestehende Applikationen optimieren wir aufgrund von geänderten Anforderungen oder steigender Systemkomplexität.',
    'text_maintenance' : 'Gerne unterstützen wir Sie bei der Wartung Ihrer Software und übernehmen den Support. Wir übernehmen die Wartung Ihrer Software - dies auch wenn wir die Systeme nicht selber entwickelt haben. Wir scheuen uns nicht, uns in fremden Code einzuarbeiten und das benötigte Know-how aufzubauen.',
    'text_consultation' : 'Unsere Kunden lassen wir gerne an unserem Know-how und unseren Erfahrungen teilhaben. Wir beraten Sie bei Vorhaben in komplexen System- und Applikationslandschaften und unterstützen Sie bei der Umsetzung Ihrer Projekte.',
    'label_news_1' : '24./25.3.2024 | Open Legal Lab 2024',
    'text_news_1' : 'Wir freuen uns auf viele spannende Begegnungen und Gespräche am diesjährigen Open Legal Lab.',
    'label_news_2' : '09./10.3.2024 | Curling Schweizermeisterschaft',
    'text_news_2' : 'Gratulation an das Curling Team CC Thun Regio zum 3. Platz an der Schweizermeisterschaft der B-Juniorinnen.',
    'title_news_2' : 'CC Thun Regio',
    'label_news_3' : '<a href="https://www.bj.admin.ch/bj/de/home/aktuell/mm.msg-id-100453.html" target="_blank">19.3.2024 | Medienmitteilung eSchKG</a>',
    'text_news_3' : '<a href="https://www.sanitycheck.ch" target="_blank">www.sanitycheck.ch</a> ist Teil dieser phänomenalen Erfolgsgeschichte.',
    'title_news_3' : '19.3.2024 | Medienmitteilung eSchKG',
    'text_news_4' : 'Wir freuen uns auf viele spannende Begegnungen und Gespräche am diesjährigen <a href="https://ejustice.ch/open-legal-lab/" target="_blank"><u>Open Legal Lab</u></a>. <p></p> Hast du eine Idee, wie die Digitalisierung das Schweizer Rechtssystem voranbringen kann? <br/>Melde dich <a href="https://ejustice.ch/open-legal-lab/#OLL2025" target="_blank"><u>hier</u></a> an und reiche deine Challenge per <a href="mailto://info@ejustice.ch?subject=OLL2025%20Challenge:" rel="nofollow" class=""><u>E-Mail</u></a> ein.',
    'label_news_4' : '06./07.04.2025 | Open Legal Lab 2025'
    
    
}


